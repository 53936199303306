/* Normalize */
@import "~normalize.css/normalize.css";

/* Generic */
html {
  height: 100%;
}

html,
body {
  box-sizing: border-box;
}
body {
  font-family: Lato, sans-serif;
  font-size: 1em;
  line-height: 1.4;
  margin: 0;
  overflow-y: scroll;
  padding: 0;
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.app {
  &__bg {
    background: url("images/bg.jpeg") center center no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
}

button:disabled,
a.disabled {
  @apply opacity-25 cursor-not-allowed;
}

.main {
  @apply flex flex-grow flex-col w-full p-5;
}

.icon-full {
  svg {
    width: 100%;
    height: 100%;
  }
}

/* Module css */
@import "../modules/Components/css/app.scss";
@import "../modules/Share/css/app.scss";
@import "../modules/Start/css/app.scss";
@import "../modules/Tournament/css/app.scss";

/* Tailwind */
@tailwind base;
@tailwind utilities;
@tailwind variants;
