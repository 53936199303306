.aside {
  @apply fixed left-0 right-0 bottom-0 mb-8;

  .table-gradient {
    background: linear-gradient(180deg, #06070d 0%, #04463e 100%);

    &::after {
      position: absolute;
      height: 50vh;
      left: 0;
      right: 0;
      bottom: 4rem;
      display: block;
      content: "";
      background: linear-gradient(180deg, #0000 0%, #000a 100%);
    }
  }

  &__table {
    @apply absolute bottom-0 left-0 right-0 h-16 border-solid border-0 border-t-4 border-black;
  }

  &__figures {
    @apply relative flex flex-row justify-between -mb-4 sm:-mb-0;
  }

  &__bubble {
    @apply bg-white absolute top-0 text-main rounded-lg px-3 py-2 left-1/2;
    transform: translateX(-50%) translateY(-100%);
    max-width: 320px;
  }
}
