/* stylelint-disable max-line-length */
.share {
  @apply fixed z-30 right-0 bottom-0 xl:right-auto xl:bottom-auto xl:left-0 xl:top-1/2 flex transform xl:-translate-y-1/2;

  &__list {
    @apply list-none p-0 m-0 flex flex-row xl:flex-col;
  }

  &__button {
    @apply flex w-12 h-12 xl:w-16 xl:h-16 items-center justify-center transition-all cursor-pointer;

    &--facebook {
      @apply bg-blue-800 text-white hover:bg-blue-950;
    }
    &--x {
      @apply bg-gray-800 text-white hover:bg-gray-950;
    }
    &--telegram {
      @apply bg-cyan-800 text-white hover:bg-cyan-950;
    }
    &--linkedin {
      @apply bg-white text-black hover:bg-gray-200;
    }
    &--pinterest {
      @apply bg-red-800 text-white hover:bg-red-950;
    }
  }
}
