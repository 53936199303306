/* stylelint-disable max-line-length */
.button {
  &__default {
    @apply bg-white border-0 font-semibold py-2 px-4 rounded shadow flex items-center text-main no-underline cursor-pointer transition ease-in-out;

    svg {
      @apply text-main transition ease-in-out;
    }

    &:hover,
    &:focus,
    &.active {
      @apply bg-secondary text-white;

      svg {
        @apply text-white;
      }
    }
  }
}
